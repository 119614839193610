<template>
  <div>
    <div class="row justify-content-between align-items-center w-100 my-4 mx-6">
      <h4 class="">Özellikler Kategorileri</h4>
      <button class="btn btn-primary text-white mx-6" @click="addFileModal = true">
        <p style="font-size: 13px" class="text-white">
          <i class="flaticon2-plus mb-1"></i> Yeni Özellik Kategorisi Ekle
        </p>
      </button>
    </div>
    <div class="row justify-content-end">
      <div class="col-3">
        <Input placeholder="Ara.." v-model="search" class="mr-2" />
      </div>
      <hr class="w-100 mx-4" />
    </div>

    <List
      :header="header"
      :data="items"
      :pagination="pagination"
      :loading="loading"
      @updatePage="updatePage"
      moduleName="Özellik Kategorileri Listesi"
      :moduleCreateEvent="true"
      :moduleSearch="search"
    >
      <template v-slot:item.email="{ item }">
        <a :href="`mailto:${item.email}`">{{ item.email }}</a>
      </template>
      <template v-slot:item.isActive="{ item }">
        <div v-html="$customFunctions.getIsActiveBadge(item.isActive)"></div>
      </template>
      <template v-slot:item.action="{ item }" class="p-0">
        <div class="d-flex justify-content-end w-100">
          <button @click="updateFeatureCategory(item)">
            <i class="flaticon-eye icon-lg"></i>
          </button>
          <button class="border-0" @click="openDeleteHandler(item)">
            <i class="flaticon2-trash text-dark text-md ml-4"></i>
          </button>
        </div>
      </template>
    </List>
    <!-- Create and Actions Popups -->

    <b-modal v-model="addFileModal" id="note-modal">
        <template #modal-header>
          <h4>Kategori Bilgileri</h4>
        </template>
        <div class="form-group">
          <div class="row">
            <div class="col-12">
              <label for="">Kategori Adı</label>
              <input type="text" class="form-control" v-model="addCategoryText" />
            </div>
          </div>
        </div>
        <template #modal-footer>
          <div class="d-flex justify-content-end w-100">
            <b-button variant="light" @click="addFileModal = false">
              Vazgeç
            </b-button>
            <b-button variant="success" class="ml-4" @click="addFeature"> Kaydet </b-button>
          </div>
        </template>
      </b-modal>
      <DeleteOperation
      :url="deleteOperationUrl"
      :params="deleteIds"
      @updateList="getList"
      modalid="deleteFeatureCategory"
    />

  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { FEATURES_CATEGORY } from "@/core/services/store/features/features.module";
import { mapGetters } from "vuex";
export default {
  name: "systemUsers",
  data() {
    return {
      addNewNote: "",
      addFileModal: false,
      addFileModal2: false,
      addCategoryText : "",
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      header: [
        { text: "İsim", value: "category_name" },
        { text: "İşlemler", value: "action" },
      ],
      items: [],
            pagination: {
        current_page: 0,
              total_pages: 0,
              total_items: this.totalItems,
              page:1

      },
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      deleteOperationUrl: "",
      deleteIds: "",
      updateModal : 0,
      category_id : ""
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Özellik Kategorileri", route: "/manage/feature" },
    ]);
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      let queryLimit = this.list_length ? this.list_length : 10;

      let start = (this.pagination.page -1 )* queryLimit
      let end = (this.pagination.page * queryLimit)-1

      let query = `&start=${start}&end=${end}&limit=${queryLimit}`;
      if (this.search) {
        query += `&search=${this.search}`;
      }
      let payload = { myUser: this.myUser, query: query };
      this.$store.dispatch(FEATURES_CATEGORY, payload).then((response) => {
        this.items = response;
        this.totalItems = response.count ? response.count : response.length;

        this.pagination = {
              current_page: this.pagination.page?this.pagination.page:1,
              page: this.pagination.page?this.pagination.page:1,
              total_pages: Math.ceil(this.totalItems /queryLimit),
              total_items: this.totalItems,
        };
        this.loading = false;
      });
    },
    updatePage(value) {
      this.meta = value;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    async addFeature() {
      const data = {
        user_token: this.myUser,
        category_name: this.addCategoryText,
      };
      if(!this.updateModal){
        await this.$ApiService
        .post("admin/featurescategories", data)
        .then((res) => {
          if (res.data.status.code === 200) {
            this.getList()

            this.$generateNotification(
              this,
              "success",
              "Varlık Başarıyla Eklenmiştir."
            )
            this.addFileModal = false
            
          } else {
            this.$generateNotification(this, "error", res.status.message);
          }
        })
        .catch((err) => {
          this.$generateNotification(this, "error", "Hata");
        });
      }else{
        data["features_id"] = this.category_id
        const formData = new FormData()
        for(const key  in data){
            formData.append(key, data[key])
        }
        this.addCategoryText = ''
            this.category_id = ''

        await this.$ApiService
        .put("admin/featurescategories", formData)
        .then((res) => {
          if (res.data.status.code === 200) {
            
            this.getList()
            
            this.$generateNotification(
              this,
              "success",
              "Varlık Başarıyla Eklenmiştir."
              )
            this.updateModal = false
            this.addFileModal = false
            
          } else {
            this.$generateNotification(this, "error", res.status.message);
          }
        })
        .catch((err) => {
          this.$generateNotification(this, "error", "Hata");
        });
      }
    },
    openDeleteHandler(item) {
      this.deleteOperationUrl = `admin/featurescategories`;
      this.deleteIds = `?user_token=${this.myUser}&featurescategories_id=${item._id.$oid}`;
      this.$bvModal.show('modal-deleteOperationdeleteFeatureCategory');
    },
    updateFeatureCategory(item){
        this.addCategoryText = item.category_name
        this.category_id = item._id.$oid
        this.updateModal = true
        this.addFileModal = true
    }
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function (params) {
      this.getList();
    },
  },
  components: {
    InsertUpdateOperations: () =>
      import("@/components/systemUser/modals/InsertUpdateOperations"),
    DetailView: () => import("@/components/systemUser/modals/DetailView"),
  },
  computed: {
    ...mapGetters(["myUser"]),
  },
};
</script>
